.body {
    margin: 0;
    /* 1 */
    line-height: inherit;
    --tw-bg-opacity: 1;
    background-color: rgb(19 29 40 / var(--tw-bg-opacity));
    font-size: 14px;
    line-height: 1.285714285714286;
    font-weight: 500;
    --tw-text-opacity: 1;
    /* color: rgb(143 157 176 / var(--tw-text-opacity)); */
    color: rgb(111 123 142 / var(--tw-text-opacity));
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.dark body {
    --tw-bg-opacity: 1;
    background-color: rgb(247 248 249 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(111 123 142 / var(--tw-text-opacity));
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 700;
    margin: 0;
}

.h3 {
    font-size: 14px;
}